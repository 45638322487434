import React from 'react';
/* eslint-disable */
export function Sent(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.5625 9.75H11.75L10.8125 11.625H5.1875L4.25 9.75H1.4375C0.910156 9.75 0.5 10.1895 0.5 10.6875V13.9688C0.5 14.7598 1.11523 15.375 1.90625 15.375H14.0938C14.8555 15.375 15.5 14.7598 15.5 13.9688V10.6875C15.5 10.1895 15.0605 9.75 14.5625 9.75ZM5.83203 4.79883L7.0625 3.59766V8.8125C7.0625 9.33984 7.47266 9.75 8 9.75C8.49805 9.75 8.9375 9.33984 8.9375 8.8125V3.59766L10.1387 4.79883C10.3145 4.97461 10.5488 5.0625 10.8125 5.0625C11.0469 5.0625 11.2812 4.97461 11.457 4.79883C11.8379 4.44727 11.8379 3.83203 11.457 3.48047L8.64453 0.667969C8.29297 0.287109 7.67773 0.287109 7.32617 0.667969L4.51367 3.48047C4.13281 3.83203 4.13281 4.44727 4.51367 4.79883C4.86523 5.17969 5.48047 5.17969 5.83203 4.79883Z"
        fill="#828299"
      />
    </svg>
  );
}
