import React from 'react';
/* eslint-disable */
export function Brand(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.5 12.5L9.90558 14.7833L14.5 9M6.87972 3.80439L5.50274 3.78799C4.27356 3.77335 3.27335 4.77356 3.28799 6.00274L3.30439 7.37972C3.31152 7.97829 3.0732 8.55365 2.64491 8.97186L1.65964 9.93393C0.78012 10.7927 0.78012 12.2073 1.65964 13.0661L2.64491 14.0281C3.0732 14.4463 3.31152 15.0217 3.30439 15.6203L3.28799 16.9973C3.27335 18.2264 4.27356 19.2266 5.50274 19.212L6.87972 19.1956C7.47829 19.1885 8.05365 19.4268 8.47186 19.8551L9.43393 20.8404C10.2927 21.7199 11.7073 21.7199 12.5661 20.8404L13.5281 19.8551C13.9463 19.4268 14.5217 19.1885 15.1203 19.1956L16.4973 19.212C17.7264 19.2266 18.7266 18.2264 18.712 16.9973L18.6956 15.6203C18.6885 15.0217 18.9268 14.4463 19.3551 14.0281L20.3404 13.0661C21.2199 12.2073 21.2199 10.7927 20.3404 9.93393L19.3551 8.97186C18.9268 8.55365 18.6885 7.97829 18.6956 7.37972L18.712 6.00274C18.7266 4.77356 17.7264 3.77335 16.4973 3.78799L15.1203 3.80439C14.5217 3.81152 13.9463 3.5732 13.5281 3.14491L12.5661 2.15964C11.7073 1.28012 10.2927 1.28012 9.43393 2.15964L8.47186 3.14491C8.05365 3.5732 7.47829 3.81152 6.87972 3.80439Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
