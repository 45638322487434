import React from 'react';

export function RemoveCircle(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M7 0.1875C3.36328 0.1875 0.4375 3.14062 0.4375 6.75C0.4375 10.3867 3.36328 13.3125 7 13.3125C10.6094 13.3125 13.5625 10.3867 13.5625 6.75C13.5625 3.14062 10.6094 0.1875 7 0.1875ZM9.1875 8.0625C9.46094 8.30859 9.46094 8.71875 9.1875 8.96484C8.94141 9.23828 8.53125 9.23828 8.28516 8.96484L7 7.67969L5.6875 8.96484C5.44141 9.23828 5.03125 9.23828 4.78516 8.96484C4.51172 8.71875 4.51172 8.30859 4.78516 8.0625L6.07031 6.75L4.78516 5.46484C4.51172 5.21875 4.51172 4.80859 4.78516 4.5625C5.03125 4.28906 5.44141 4.28906 5.6875 4.5625L7 5.84766L8.28516 4.5625C8.53125 4.28906 8.94141 4.28906 9.1875 4.5625C9.46094 4.80859 9.46094 5.21875 9.1875 5.46484L7.90234 6.75L9.1875 8.0625Z"
        fill="white"
      />
    </svg>
  );
}
