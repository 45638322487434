import React from 'react';
/* eslint-disable */
export function Edit(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2071 6.32843C22.4174 6.11814 22.7026 6 23 6C23.2974 6 23.5826 6.11814 23.7929 6.32843C24.0032 6.53872 24.1213 6.82393 24.1213 7.12132C24.1213 7.41871 24.0032 7.70392 23.7929 7.91421L14.4888 17.2184L12.3744 17.747L12.903 15.6326L22.2071 6.32843ZM23 4C22.1722 4 21.3783 4.32885 20.7929 4.91421L11.2929 14.4142C11.1647 14.5424 11.0738 14.703 11.0299 14.8788L10.0299 18.8788C9.94466 19.2196 10.0445 19.58 10.2929 19.8284C10.5413 20.0768 10.9018 20.1767 11.2425 20.0915L15.2425 19.0915C15.4184 19.0475 15.5789 18.9566 15.7071 18.8284L25.2071 9.32843C25.7925 8.74307 26.1213 7.94915 26.1213 7.12132C26.1213 6.29349 25.7925 5.49957 25.2071 4.91421C24.6217 4.32885 23.8278 4 23 4ZM7 6.12109C6.20435 6.12109 5.44129 6.43716 4.87868 6.99977C4.31607 7.56238 4 8.32544 4 9.12109V23.1211C4 23.9167 4.31607 24.6798 4.87868 25.2424C5.44129 25.805 6.20435 26.1211 7 26.1211H21C21.7957 26.1211 22.5587 25.805 23.1213 25.2424C23.6839 24.6798 24 23.9167 24 23.1211V16.1211C24 15.5688 23.5523 15.1211 23 15.1211C22.4477 15.1211 22 15.5688 22 16.1211V23.1211C22 23.3863 21.8946 23.6407 21.7071 23.8282C21.5196 24.0157 21.2652 24.1211 21 24.1211H7C6.73478 24.1211 6.48043 24.0157 6.29289 23.8282C6.10536 23.6407 6 23.3863 6 23.1211V9.12109C6 8.85588 6.10536 8.60152 6.29289 8.41399C6.48043 8.22645 6.73478 8.12109 7 8.12109H14C14.5523 8.12109 15 7.67338 15 7.12109C15 6.56881 14.5523 6.12109 14 6.12109H7Z"
        fill="currentColor"
      />
    </svg>
  );
}
