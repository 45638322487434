import React from 'react';
/* eslint-disable */
export function Received(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.32617 9.48633C7.67773 9.86719 8.29297 9.86719 8.64453 9.48633L11.457 6.67383C11.8379 6.32227 11.8379 5.70703 11.457 5.35547C11.1055 4.97461 10.4902 4.97461 10.1387 5.35547L8.9375 6.55664V1.3125C8.9375 0.814453 8.49805 0.375 8 0.375C7.47266 0.375 7.0625 0.814453 7.0625 1.3125V6.55664L5.83203 5.35547C5.65625 5.17969 5.42188 5.0625 5.1875 5.0625C4.92383 5.0625 4.68945 5.17969 4.51367 5.35547C4.13281 5.70703 4.13281 6.32227 4.51367 6.67383L7.32617 9.48633ZM14.5625 9.75H11.75L10.8125 11.625H5.1875L4.25 9.75H1.4375C0.910156 9.75 0.5 10.1895 0.5 10.6875V13.9688C0.5 14.7598 1.11523 15.375 1.90625 15.375H14.0938C14.8555 15.375 15.5 14.7598 15.5 13.9688V10.6875C15.5 10.1895 15.0605 9.75 14.5625 9.75Z"
        fill="#828299"
      />
    </svg>
  );
}
