import React from 'react';
/* eslint-disable */
export function CheckCircle(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M8.25 0.5C4.09375 0.5 0.75 3.875 0.75 8C0.75 12.1562 4.09375 15.5 8.25 15.5C12.375 15.5 15.75 12.1562 15.75 8C15.75 3.875 12.375 0.5 8.25 0.5ZM11.8438 6.625L7.84375 10.625C7.6875 10.8125 7.46875 10.875 7.25 10.875C7 10.875 6.78125 10.8125 6.625 10.625L4.625 8.625C4.28125 8.28125 4.28125 7.75 4.625 7.40625C4.96875 7.0625 5.5 7.0625 5.84375 7.40625L7.25 8.78125L10.625 5.40625C10.9688 5.0625 11.5 5.0625 11.8438 5.40625C12.1875 5.75 12.1875 6.28125 11.8438 6.625Z"
        fill="currentColor"
      />
    </svg>
  );
}
